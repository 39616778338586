import { useState, useEffect } from 'react'
import './Finances.css'
import NavBar from '../components/NavBar.js'
import { makeDayISO, makeMonthISO, makeYearISO } from '../Logic/DateFormating.js'
import { STORE } from '../STORE.js'
import { useNavigate } from 'react-router-dom'

const PlanFact = () => {
    
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [filters, setFilters] = useState()
    const [data, setData] = useState()

    const API = 'https://eternity-tracker.ru/api/finances/payment-calendar/'

    useEffect(() => {
        setLoading(true)
        fetch(API, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Authorization': `Token ${STORE.readToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                exclude_debets: [6]
            })
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data)
            setLoading(false)
        if (data.detail === 'Invalid token') {
            navigate('/login')
        }
        })
        .catch(error => console.error(error))
    }, [])


    if (loading) {
        return (
            <body style={{height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <svg class="loader" viewBox="0 0 384 384" xmlns="http://www.w3.org/2000/svg">
        <circle
        class="active"
        pathLength="360"
        fill="transparent"
        stroke-width="32"
        cx="192"
        cy="192"
        r="176"
        ></circle>
        <circle
        class="track"
        pathLength="360"
        fill="transparent"
        stroke-width="32"
        cx="192"
        cy="192"
        r="176"
        ></circle>
        </svg>
        </body>
        )
    } else {

    return (
        <body>
        <div className='helper_container_page' style={{ minHeight: '40vmax', width: 'fit-content' }}>
                <NavBar />
                <div className='planfact_container'>

                </div>
            </div>
            </body>
    )}
    
}

export default PlanFact